import React from "react";

import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/SEO";
import Headings from "../components/Headings";

const NotFoundPage = () => (
  <Layout>
    <SEO />
    <Section>
      <div style={{ marginTop: "100px" }}>
        <Headings.h1>404: Page Not Found</Headings.h1>
      </div>
    </Section>
  </Layout>
);

export default NotFoundPage;
